import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8c270d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = ["for", "title"]
const _hoisted_3 = { class: "col-3 col-xxl-2 d-flex justify-content-end" }
const _hoisted_4 = ["id", "type", "value"]
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "form-label col mb-0 text-truncate",
      for: _ctx.variable.variable,
      title: _ctx.variable.label
    }, _toDisplayString(_ctx.variable.label), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.variable.variable,
        class: _normalizeClass(["form-control", `form-${_ctx.variable.type}`]),
        "data-test-id": "input-variable",
        min: "0",
        type: _ctx.variable.type,
        value: _ctx.variable.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 42, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn btn-square btn-outline-light square-sm",
        "data-test-id": "btn-reset-variable",
        disabled: !_ctx.valueChanged,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetVariable(_ctx.variable)))
      }, [
        _createVNode(_component_Fa, { icon: "reload-large" })
      ], 8, _hoisted_6)
    ])
  ]))
}