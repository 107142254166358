
import {PropType, defineComponent} from 'vue';
import {ThemeVariable} from '@/components/themeBuilder/ThemeVariables';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    props: {
        variable: {
            type: Object as PropType<ThemeVariable>,
            required: true,
        },
    },
    emits: [
        'update:modelValue',
    ],
    setup(props) {
        const {
            getOriginalVariable,
            resetVariable,
        } = themeBuilderStore;

        const originalVariable: ThemeVariable = getOriginalVariable(props.variable);

        return {
            originalVariable,
            resetVariable,
        };
    },
    computed: {
        valueChanged(): boolean {
            return this.variable.value !== this.originalVariable.value;
        },
    },
});
