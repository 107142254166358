import {ThemeVariable, themeVariables} from '@/components/themeBuilder/ThemeVariables';
import {DefaultConversions} from '@/models/Media';
import Model from '@/models/Model';

export interface ThemeFontWeight {
    fileName?: string;
    value?: string;
    weight: number | 'variable';
}

interface ThemeFont {
    fontWeights: ThemeFontWeight[];
    // Indicates whether the font is from an external source (link).
    isExternal: boolean;
    // Indicates whether the font is a variable font.
    isVariable: boolean;
    value?: string;
}

export interface BrandingData {
    createdAt?: string;
    favicon?: string;
    fontName: string;
    fonts: ThemeFont;
    id?: number;
    onboardingImage?: DefaultConversions;
    scssVariables: Record<string, any> | ThemeVariable[];
    updatedAt?: string;
}

class Branding extends Model<BrandingData> {
    /**
     * @inheritdoc
     */
    endpoint = 'branding';

    // Get the scss variables as an array of theme variables.
    get themeVariables(): ThemeVariable[] {
        return Array.isArray(this.scssVariables)
            ? this.scssVariables
            : [];
    }

    /**
     * @inheritdoc
     */
    fillAttributes(attributes: Partial<BrandingData>): void {
        const fonts: ThemeFont = {
            fontWeights: [],
            isExternal: false,
            isVariable: false,
            value: '',
        };

        /*
         * The API doesn't return the fonts in our neat ThemeFont interface.
         * If the value is a string, it is a Google Fonts link. Otherwise, it is
         * an Object with font weights as keys and file contents as value.
         */
        const attributeFonts = attributes.fonts as string | Record<string, any>;

        if (typeof attributeFonts === 'string') {
            fonts.isExternal = true;
            fonts.value = attributeFonts;
        } else {
            fonts.isVariable = Object.keys(attributeFonts)[0] === 'variable';
            fonts.fontWeights = Object.entries(attributeFonts).map(([key, value]: [string, any]): ThemeFontWeight => {
                /*
                 * Try and parse the font weight to a number, otherwise the
                 * string value is 'variable'.
                 */
                const weight = Number(key) || key;

                return {
                    fileName: `${attributes.fontName}-${key}`,
                    value,
                    weight: weight as 'variable' | number,
                };
            });
        }

        super.fillAttributes({
            ...attributes,
            fonts,
        });

        /*
         * If the SCSS variables aren't defined or the variables are defined
         * as an array (of ThemeVariables), return.
         */
        if (
            !attributes.scssVariables
            || Array.isArray(attributes.scssVariables)
        ) {
            return;
        }

        /*
         * The API returns the variables as an Object which will be transformed
         * to an array of ThemeVariables.
         */
        const variables = attributes.scssVariables as Record<string, any>;

        const mappedVariables = themeVariables.map((variable: ThemeVariable) => {
            // Get the variable value.
            const brandingValue = variables[variable.variable] && variables[variable.variable].toString();

            // If no value exists, return the default theme variable.
            if (!brandingValue) {
                return variable;
            }

            // Check if theme variable has a suffix and if so, get the index.
            const suffixIndex = variable.suffix && brandingValue.indexOf(variable.suffix);

            // Remove the suffix from the branding value.
            const value = suffixIndex && suffixIndex > -1
                ? brandingValue.substring(0, suffixIndex)
                : brandingValue;

            // Return the theme variable with the value from the API.
            return {
                ...variable,
                value,
            };
        });

        this.set('scssVariables', mappedVariables);
    }

    /**
     * @inheritdoc
     */
    getDefaults(): BrandingData {
        return {
            fontName: '',
            fonts: {
                fontWeights: [],
                isExternal: false,
                isVariable: false,
            },
            scssVariables: themeVariables,
        };
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const fonts = this.fonts.isExternal
            // If the font is external, return the string value (the font link)
            ? this.fonts.value
            /*
             * Otherwise map the font weights to an Object with the weight as key
             * and the font file data as value.
             */
            : this.fonts.fontWeights.reduce((fontWeights: Record<number|string, any>, fontWeight: ThemeFontWeight) => {
                fontWeights[fontWeight.weight] = fontWeight.value;

                return fontWeights;
            }, {});

        // Transform the theme variables to an Object.
        const scssVariables = this.themeVariables.reduce((variables: Record<string, any>, themeVariable: ThemeVariable) => {
            // Set the value to the theme variable value with the optional suffix.
            variables[themeVariable.variable] = themeVariable.value + (themeVariable.suffix || '');

            return variables;
        }, {});

        const data: Record<string, any> = {
            fontName: this.fontName,
            fonts,
            scssVariables,
        };

        if (this.onboardingImage?.original !== this.original.onboardingImage?.original) {
            data.onboardingImage = this.onboardingImage?.original;
        }

        return data;
    }
}

interface Branding extends BrandingData {}

export default Branding;
