import Branding, {ThemeFontWeight} from '@/models/Branding';
import {computed, ref} from 'vue';
import {ThemeVariable} from '@/components/themeBuilder/ThemeVariables';
import config from '@/library/data/config';
import userTeamsStore from '@/store/userTeams';

/*
 * ----------------------------------------------------------------------------
 * - Constants ----------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * The available font weights.
 */
const fontWeights: number[] = [300, 400, 500, 600, 700];

/*
 * ----------------------------------------------------------------------------
 * - State --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * The branding model.
 */
const branding = ref<Branding>(new Branding());

/**
 * Returns whether the font has value and can be loaded.
 */
const canLoadFont = computed<boolean>(() => {
    const fontHasValue = branding.value.fonts.isExternal
        ? !!branding.value.fonts.value
        : !!branding.value.fonts.fontWeights.length;

    return !!branding.value.fontName
        && fontHasValue;
});

/**
 * The converted CSS variables.
 */
const cssVariables = computed<Record<string, any>>(() => {
    return branding.value.themeVariables.reduce((variables: Record<string, any>, themeVariable: ThemeVariable) => {
        variables[`--${themeVariable.variable}`] = themeVariable.value + (themeVariable.suffix || '');

        return variables;
    }, {});
});

/**
 * The theme Easter egg value.
 */
const themeEgg = ref<string>('');

/**
 * Returns whether the theme is magic ✨.
 */
const themeIsMagic = computed<boolean>(() => {
    return [
        'fairy', 'fairies',
        'unicorn', 'unicorns',
        'rainbow', 'rainbows',
    ].includes(themeEgg.value.toLowerCase());
});

/*
 * ----------------------------------------------------------------------------
 * - Methods ------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Clear the font name and remove all loaded fonts.
 */
const clearFonts = (): void => {
    branding.value.fontName = '';

    removeFonts();
};

/**
 * Fetches the team's branding.
 */
const fetch = async (): Promise<void> => {
    const teamId = userTeamsStore.currentTeam.value?.id;

    try {
        await branding.value.fetch({
            url: `/teams/${teamId}/branding`,
        });
    } catch (e: any) {
        if (!e.response || !e.response.data.errors) throw e;
    }

    if (canLoadFont.value) {
        loadFonts();
    }
};

/**
 * Find the Theme Font Weight for a specific weight.
 */
const findFontWeight = (weight: typeof fontWeights[number] | 'variable'): ThemeFontWeight | undefined => {
    const weightIndex = findFontWeightIndex(weight);

    return weightIndex > -1
        ? branding.value.fonts.fontWeights[weightIndex]
        : undefined;
};

/**
 * Find the Theme Font Weight index for a specific weight.
 */
const findFontWeightIndex = (weight: typeof fontWeights[number] | 'variable'): number => {
    return branding.value.fonts.fontWeights.findIndex((fontWeight: ThemeFontWeight) => fontWeight.weight === weight);
};

/**
 * Find the Original Variable of a theme variable.
 */
const getOriginalVariable = (variable: ThemeVariable): ThemeVariable => {
    const originalVariables: ThemeVariable[] = branding.value.original.scssVariables as ThemeVariable[];

    return originalVariables.find((themeVariable: ThemeVariable) => themeVariable.variable === variable.variable)!;
};

/**
 * Load the fonts into the document.
 */
const loadFonts = (): void => {
    removeFonts();

    if (branding.value.fonts.isExternal) {
        const fontLink = document.createElement('link');

        fontLink.rel = 'stylesheet';
        fontLink.href = branding.value.fonts.value!;
        fontLink.id = config.THEME_BUILDER_STYLESHEET_LINK_ID;

        document.body.appendChild(fontLink);

        return;
    }

    if (!branding.value.fonts.fontWeights.length) {
        return;
    }

    branding.value.fonts.fontWeights.forEach((themeFontWeight: ThemeFontWeight) => {
        if (!themeFontWeight.value) {
            return;
        }

        const descriptors = themeFontWeight.weight !== 'variable'
            ? {weight: themeFontWeight.weight.toString()}
            : {};

        const fontWeightFont = new FontFace(
            branding.value.fontName,
            `url(${themeFontWeight.value})`,
            descriptors,
        );

        fontWeightFont.load().then((fontFace: FontFace) => {
            document.fonts.add(fontFace);
        });
    });
};

/**
 * Remove the Theme Font Weight for a specific weight.
 */
const removeFontWeight = (weight: typeof fontWeights[number] | 'variable'): void => {
    const weightIndex = findFontWeightIndex(weight);

    if (weightIndex < 0) {
        return;
    }

    branding.value.fonts.fontWeights.splice(weightIndex, 1);
};

/**
 * Remove all added fonts from the document.
 */
const removeFonts = (): void => {
    const fontLink = document.getElementById(config.THEME_BUILDER_STYLESHEET_LINK_ID);

    if (fontLink) {
        document.body.removeChild(fontLink);
    }

    document.fonts.clear();
};

/**
 * Reset the font weights.
 */
const resetFontWeights = (): void => {
    removeFonts();

    branding.value.fonts.fontWeights = [];
};

/**
 * Reset the font source.
 */
const resetFontSource = (): void => {
    removeFonts();

    branding.value.fonts.isVariable = false;
    branding.value.fonts.fontWeights = [];
    branding.value.fonts.value = '';
};

/**
 * Reset a variable to its original value.
 */
const resetVariable = (variable: ThemeVariable): void => {
    const originalVariable = getOriginalVariable(variable);

    variable.value = originalVariable.value;
};

/**
 * Save the team's branding.
 */
const save = async (): Promise<void> => {
    const teamId = userTeamsStore.currentTeam.value?.id;

    try {
        await branding.value.update({
            url: `/teams/${teamId}/branding`,
        });
    } catch (e: any) {
        if (!e.response || !e.response.data.errors) throw e;
    }
};

/*
 * ----------------------------------------------------------------------------
 * - Store --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

export default {
    branding,
    canLoadFont,
    cssVariables,
    fontWeights,
    themeEgg,
    themeIsMagic,

    clearFonts,
    fetch,
    findFontWeight,
    findFontWeightIndex,
    getOriginalVariable,
    loadFonts,
    removeFontWeight,
    removeFonts,
    resetFontWeights,
    resetFontSource,
    resetVariable,
    save,
};
