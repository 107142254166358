import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d97e3f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row gx-10" }
const _hoisted_2 = { class: "col-4 vh-100 py-12 position-sticky top-0 z-index-1" }
const _hoisted_3 = { class: "row flex-column h-100 bg-white mimic-border mimic-rounded p-4" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "col flex-grow-0" }
const _hoisted_7 = { class: "row justify-content-between align-items-end gx-0" }
const _hoisted_8 = { class: "col-4 d-grid" }
const _hoisted_9 = { class: "col-4 d-grid" }
const _hoisted_10 = {
  key: 0,
  class: "col-12 mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Error = _resolveComponent("Error")!
  const _component_ComponentMimics = _resolveComponent("ComponentMimics")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-fluid theme-builder px-20 min-vh-100", {magical: _ctx.themeIsMagic}]),
    "data-test-id": "theme-builder-root",
    style: _normalizeStyle(_ctx.cssVariables)
  }, [
    _createVNode(_component_WaitForResource, {
      class: "vh-100",
      "hide-error": _ctx.hideError,
      resource: _ctx.branding
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('themeBuilder.title')), 1)
              ]),
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.inputSteps[_ctx.inputStep]), { class: "col overflow-auto scrollbar" })),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.inputStep)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary",
                          "data-test-id": "btn-input-prev-step",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputStep--))
                        }, _toDisplayString(_ctx.$t('themeBuilder.actions.prevStep')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.lastInputStepReached)
                      ? (_openBlock(), _createBlock(_component_BtnResource, {
                          key: 0,
                          "data-test-id": "btn-save-branding",
                          resource: _ctx.branding,
                          onClick: _ctx.saveBranding
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('themeBuilder.actions.saveBranding')), 1)
                          ]),
                          _: 1
                        }, 8, ["resource", "onClick"]))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-primary",
                          "data-test-id": "btn-input-next-step",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputStep++))
                        }, _toDisplayString(_ctx.$t('themeBuilder.actions.nextStep')), 1))
                  ]),
                  (_ctx.branding.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_Error, {
                          class: "d-block",
                          errors: _ctx.branding.errors
                        }, null, 8, ["errors"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createVNode(_component_ComponentMimics, {
            class: "col-8 py-12 z-index-1",
            style: _normalizeStyle(_ctx.mimicComponentsStyle)
          }, null, 8, ["style"])
        ])
      ]),
      _: 1
    }, 8, ["hide-error", "resource"])
  ], 6))
}