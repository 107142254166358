<template>
    <div>
        <h2 class="mb-6 mimic-border-bottom">
            {{ $t('themeBuilder.quill.title') }}
        </h2>

        <h5 class="mb-6">
            Introduction

            <span class="mimic-word-count ms-4 fs-7">
                37/40 words
            </span>
        </h5>

        <div class="mimic-quill-instruction mb-6 p-6 mimic-rounded-3">
            <div class="row">
                <div class="col-auto">
                    <img
                        class="square-sm rounded-circle"
                        :src="require('@/assets/images/david.png')"
                    >
                </div>

                <div class="col">
                    <div>
                        Hi User, there are several ways to open your pitch. We explain the ins and outs of a great introduction in the movies, and we’re sharing the most common ways to open a pitch in the tips & examples section on the right.
                    </div>

                    <div class="mt-4">
                        <button class="btn btn-sm mimic-instruction-button">
                            Play video

                            <Fa icon="play" />
                        </button>

                        <button class="btn btn-sm mimic-instruction-button">
                            Show examples

                            <Fa icon="bolt" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mimic-quill-content">
            <p>This is the pitch text editor, where you can write and style you text.</p>

            <p>The editor supports various styling options, like;</p>

            <ul>
                <li>
                    <strong>Bold text</strong>
                </li>
                <li>
                    <em>Italicized text</em>
                </li>
                <li>
                    <u>Underlined text</u>
                </li>
            </ul>

            <p>In addition to text styling, we have highlight colors for various occasions;</p>

            <ul>
                <li>
                    <span class="mimic-quill-selection-bg">
                        This is text that has</span>
                    been selected
                </li>
                <li>
                    <span class="mimic-quill-comment-highlight-bg">
                        This is text that has</span>
                    a comment linked to it
                </li>
                <li>
                    <span class="mimic-quill-comment-highlight-active-bg">
                        This is text that has</span>
                    the active comment linked to it
                </li>
                <li>
                    <span class="mimic-quill-comment-highlight-new-bg">
                        This is text on which</span>
                    a new comment will be placed
                </li>
            </ul>
        </div>

        <h5 class="my-6">
            Demonstrate

            <span class="mimic-word-count ms-4 fs-7">
                0/40 words
            </span>
        </h5>

        <div class="mimic-quill-instruction mb-6 p-6 mimic-rounded-3">
            <div class="row">
                <div class="col-auto">
                    <img
                        class="square-sm rounded-circle"
                        :src="require('@/assets/images/david.png')"
                    >
                </div>

                <div class="col">
                    <div>
                        Hi User, after introducing the subject, now it's time to show your product. See our tips & tricks on the right to get inspired!
                    </div>

                    <div class="mt-4">
                        <button class="btn btn-sm mimic-instruction-button">
                            Play video

                            <Fa icon="play" />
                        </button>

                        <button class="btn btn-sm mimic-instruction-button">
                            Show examples

                            <Fa icon="bolt" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mimic-quill-content position-relative mb-6">
            <div class="mimic-quill-placeholder">
                The beginning of something great...
            </div>

            <div class="mimic-tooltip position-absolute top-50p">
                <div class="mimic-tooltip-arrow position-absolute top-50p" />

                <div class="mimic-tooltip-inner">
                    Start writing your subject
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.mimic-border-bottom {
    border-bottom: 1px solid var(--border-color);
}

.mimic-word-count {
    color: var(--primary);
}

.mimic-rounded-3 {
    border-radius: calc(var(--border-radius) * 1.5);
}

.mimic-quill-instruction {
    background-color: var(--gray-100);
}

.mimic-quill-selection-bg {
    background-color: var(--quill-selection-bg);
}

.mimic-quill-comment-highlight-bg {
    background-color: var(--quill-comment-highlight-bg);
}

.mimic-quill-comment-highlight-active-bg {
    background-color: var(--quill-comment-highlight-active-bg);
}

.mimic-quill-comment-highlight-new-bg {
    background-color: var(--quill-comment-highlight-new-bg);
}

.mimic-instruction-button {
    border-radius: var(--border-radius);
    color: var(--instruction-action-color);

    &:hover {
        background-color: var(--instruction-action-hover-bg);
        color: var(--instruction-action-hover-color);
    }

    &:active {
        background-color: var(--instruction-action-active-bg);
    }
}

.mimic-quill-placeholder {
    color: var(--text-muted);
}

.mimic-tooltip {
    left: 40%;
    transform: translate(-50%, -50%);
}

.mimic-tooltip-arrow {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-color: transparent var(--tooltip-arrow-color) transparent transparent;
    border-style: solid;
    transform: translate(-100%, -50%);
}

.mimic-tooltip-inner {
    padding: .5rem 1rem;
    color: #fff;
    background-color: var(--tooltip-bg);
    border-radius: var(--border-radius);
    font-size: .875rem;
}
</style>
