
import {defineComponent, ref} from 'vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import InputFont from '@/components/themeBuilder/InputFont.vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    components: {
        InputFont,
        FormGroup,
    },
    setup() {
        const {
            branding,
            findFontWeight,
            fontWeights,
            removeFontWeight,
            resetFontWeights,
            resetFontSource,
        } = themeBuilderStore;

        return {
            branding,
            findFontWeight,
            fontWeights,
            removeFontWeight,
            resetFontWeights,
            resetFontSource,
            fontType: ref<string>('file'),
        };
    },
    computed: {
        uploadFontWeights(): (number | 'variable')[] {
            return this.branding.fonts.isVariable
                ? ['variable']
                : this.fontWeights;
        },
    },
    methods: {
        toggleExternalFont(isExternal: boolean) {
            this.branding.fonts.isExternal = isExternal;

            this.resetFontSource();
        },
        toggleVariableFont() {
            this.branding.fonts.isVariable = !this.branding.fonts.isVariable;

            this.resetFontWeights();
        },
        uploadFontWeight(weight: number | 'variable', fontData: Record<string, any>) {
            const themeFontWeight = this.findFontWeight(weight);

            if (!themeFontWeight) {
                this.branding.fonts.fontWeights.push({
                    fileName: fontData.fileName,
                    weight,
                    value: fontData.value,
                });
            } else {
                themeFontWeight.fileName = fontData.fileName;
                themeFontWeight.value = fontData.value;
            }
        },
    },
});
