
import {PropType, defineComponent} from 'vue';
import {ThemeFontWeight} from '@/models/Branding';

export default defineComponent({
    props: {
        fontWeight: {
            type: [String, Number],
            required: true,
        },
        themeFontWeight: {
            type: Object as PropType<ThemeFontWeight>,
            default: null,
        },
    },
    emits: [
        'font-removed',
        'font-uploaded',
    ],
    methods: {
        uploadFontWeight(event: Event) {
            const inputElement = event.target as HTMLInputElement;
            const fontFile = inputElement.files![0];

            if (!fontFile) {
                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                if (!reader.result) {
                    return;
                }

                this.$emit('font-uploaded', {
                    fileName: fontFile.name,
                    value: reader.result,
                });
            };

            reader.readAsDataURL(fontFile);

            inputElement.value = '';
        },
    },
});
