
import InputVariable from '@/components/themeBuilder/InputVariable.vue';
import {ThemeVariable} from '@/components/themeBuilder/ThemeVariables';
import {defineComponent} from 'vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    components: {
        InputVariable,
    },
    setup() {
        const {branding} = themeBuilderStore;

        return {
            branding,
        };
    },
    methods: {
        setVariableValue(variable: ThemeVariable, value: string | number) {
            variable.value = value;
        },
    },
});
