import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(component), {
        key: `component-${index}`,
        class: _normalizeClass(["mimic-rounded p-6 bg-white mimic-border overflow-hidden", {'mt-4': index}])
      }, null, 8, ["class"]))
    }), 128))
  ]))
}