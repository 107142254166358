import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-4 position-sticky top-0 bg-white" }
const _hoisted_2 = { class: "mb-8" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "pb-4 position-sticky top-0 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadFonts = _resolveComponent("UploadFonts")!
  const _component_ImageField = _resolveComponent("ImageField")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('themeBuilder.fonts.title')), 1),
    _createVNode(_component_UploadFonts),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        "data-test-id": "btn-load-font",
        disabled: !_ctx.canLoadFont,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadFonts && _ctx.loadFonts(...args)))
      }, _toDisplayString(_ctx.$t('themeBuilder.fonts.actions.loadFont')), 9, _hoisted_3),
      _createElementVNode("button", {
        class: "btn btn-light ms-4",
        "data-test-id": "btn-clear-font",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearFonts && _ctx.clearFonts(...args)))
      }, _toDisplayString(_ctx.$t('themeBuilder.fonts.actions.clearFont')), 1)
    ]),
    _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('themeBuilder.onboardingImage.title')), 1),
    _createVNode(_component_ImageField, {
      "error-key": "onboardingImage",
      errors: _ctx.branding.errors,
      "model-value": _ctx.branding.onboardingImage,
      name: "onboarding-image",
      size: "lg",
      "onUpdate:modelValue": _ctx.setOnboardingImage
    }, null, 8, ["errors", "model-value", "onUpdate:modelValue"])
  ]))
}