import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center flex-nowrap" }
const _hoisted_2 = { class: "col-6 d-grid" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "col text-truncate" }
const _hoisted_6 = {
  key: 0,
  class: "col-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        class: "btn btn-primary btn-sm",
        for: `input-font-weight-${_ctx.fontWeight}`
      }, _toDisplayString(_ctx.$t(`themeBuilder.fonts.fontWeights.${_ctx.fontWeight}`)), 9, _hoisted_3),
      _createElementVNode("input", {
        id: `input-font-weight-${_ctx.fontWeight}`,
        accept: ".ttf,.otf,.woff,.woff2",
        hidden: "",
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFontWeight && _ctx.uploadFontWeight(...args)))
      }, null, 40, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.themeFontWeight?.fileName), 1),
    (_ctx.themeFontWeight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "btn btn-outline-danger btn-circle btn-xxs d-flex align-items-center justify-content-center",
            "data-test-id": "btn-remove-font",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('font-removed')))
          }, [
            _createVNode(_component_Fa, {
              icon: "cross-large",
              size: "xs"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}