
import Media from '@/models/Media';
import VideoPlayer from '@/components/common/plyr/VideoPlayer.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        VideoPlayer,
    },
    setup() {
        return {
            media: new Media({
                url: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8',
            }),
        };
    },
});
