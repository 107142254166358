import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03d6ce8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6 mimic-border-bottom" }
const _hoisted_2 = { class: "table mimic-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('themeBuilder.table.title')), 1),
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return (_openBlock(), _createElementBlock("th", {
              key: header,
              class: "whitespace-nowrap py-4"
            }, _toDisplayString(header), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `row-${index}`,
            class: "cursor-pointer"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
              return (_openBlock(), _createElementBlock("td", {
                key: `row-${header}-${index}`
              }, _toDisplayString(row[header]), 1))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}