
import ButtonsFormsMimic from '@/components/themeBuilder/ButtonsFormsMimic.vue';
import FaviconMimic from '@/components/themeBuilder/FaviconMimic.vue';
import OnboardingMimic from '@/components/themeBuilder/OnboardingMimic.vue';
import PlyrMimic from '@/components/themeBuilder/PlyrMimic.vue';
import QuillMimic from '@/components/themeBuilder/QuillMimic.vue';
import TableMimic from '@/components/themeBuilder/TableMimic.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        ButtonsFormsMimic,
        FaviconMimic,
        OnboardingMimic,
        PlyrMimic,
        QuillMimic,
        TableMimic,
    },
    setup() {
        const components: any[] = [
            ButtonsFormsMimic,
            QuillMimic,
            TableMimic,
            PlyrMimic,
            FaviconMimic,
            OnboardingMimic,
        ];

        return {
            components,
        };
    },
});
