import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6 mimic-border-bottom" }
const _hoisted_2 = { class: "mimic-plyr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('themeBuilder.plyr.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VideoPlayer, {
        "offset-fraction": 0.5,
        video: _ctx.media
      }, null, 8, ["offset-fraction", "video"])
    ])
  ]))
}