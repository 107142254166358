
import {defineComponent} from 'vue';

export default defineComponent({
    setup() {
        return {
            headers: [
                'Id',
                'Pitch Name',
                'Team',
                'Author',
            ],
            rows: [
                {
                    Id: 1,
                    'Pitch Name': 'Introducing the New Normal',
                    Team: 'Team OWOW',
                    Author: 'Robin Dohmen',
                },
                {
                    Id: 2,
                    'Pitch Name': 'Turning the world Upside Down',
                    Team: 'Team MOMO',
                    Author: 'Dees Oomens',
                },
                {
                    Id: 3,
                    'Pitch Name': 'The Hunt for Satisfaction',
                    Team: 'Team OWOW',
                    Author: 'Tim Dirks',
                },
            ],
        };
    },
});
