import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-4 position-sticky top-0 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputVariable = _resolveComponent("InputVariable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('themeBuilder.variables.title')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.branding.themeVariables, (themeVariable) => {
      return (_openBlock(), _createBlock(_component_InputVariable, {
        key: themeVariable.variable,
        class: "mb-2",
        "data-test-id": `input-variable-${themeVariable.variable}`,
        variable: themeVariable,
        "onUpdate:modelValue": ($event: any) => (_ctx.setVariableValue(themeVariable, $event))
      }, null, 8, ["data-test-id", "variable", "onUpdate:modelValue"]))
    }), 128))
  ]))
}