
import ImageField from '@/components/common/form/ImageField.vue';
import UploadFonts from '@/components/themeBuilder/UploadFonts.vue';
import {defineComponent} from 'vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    components: {
        ImageField,
        UploadFonts,
    },
    setup() {
        const {
            branding,
            canLoadFont,
            clearFonts,
            loadFonts,
        } = themeBuilderStore;

        return {
            branding,
            canLoadFont,
            clearFonts,
            loadFonts,
        };
    },
    methods: {
        setOnboardingImage(image: string) {
            if (!this.branding.onboardingImage) {
                this.branding.onboardingImage = {
                    original: image,
                };

                return;
            }

            this.branding.onboardingImage.original = image;
        },
    },
});
