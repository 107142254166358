<template>
    <div>
        <h2 class="mb-6 mimic-border-bottom">
            {{ $t('themeBuilder.favicon.title') }}
        </h2>

        <div class="text-center">
            <svg
                fill="none"
                height="256"
                viewBox="0 0 100 100"
                width="256"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    class="mimic-favicon-background"
                    height="100"
                    rx="8"
                    width="100"
                />

                <g clip-path="url(#icon-clip)">
                    <path
                        d="M50 16C36 16 24 27 24 41H24V84L41 66C76 77 89 30 60 18 57 17 54 16 50 16ZM50 56C42 56 35 50 35 41H41C41 46 45 50 50 50 55 50 59 46 59 41H65C65 50 58 56 50 56Z"
                        fill="white"
                    />
                </g>

                <defs>
                    <clipPath id="icon-clip">
                        <rect
                            fill="white"
                            height="68"
                            transform="translate(24 16)"
                            width="52"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.mimic-border-bottom {
    border-bottom: 1px solid var(--border-color);
}

.mimic-favicon-background {
    fill: var(--favicon-bg);
}
</style>
