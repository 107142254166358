import {t} from '@/vendor/I18n';

type VariableType = 'color' | 'number';

interface ThemeVariable {
    label: string;
    variable: string;
    value: string | number;
    type: VariableType;
    suffix?: string;
}

const themeVariables: ThemeVariable[] = [
    {
        label: t('themeBuilder.variables.gray-050'),
        variable: 'gray-050',
        value: '#fafbfe',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.gray-100'),
        variable: 'gray-100',
        value: '#f8f9fd',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.gray-500'),
        variable: 'gray-500',
        value: '#9cadc6',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.primary'),
        variable: 'primary',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.body-color'),
        variable: 'body-color',
        value: '#030335',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.border-color'),
        variable: 'border-color',
        value: '#f0f2fa',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.text-muted'),
        variable: 'text-muted',
        value: '#9cadc6',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.btn-hover-primary'),
        variable: 'btn-hover-primary',
        value: '#2a3bda',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.btn-disabled-primary'),
        variable: 'btn-disabled-primary',
        value: '#6388fe',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.input-disabled-color'),
        variable: 'input-disabled-color',
        value: '#495057',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.input-disabled-bg'),
        variable: 'input-disabled-bg',
        value: '#f0f2fa',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.input-focus-border-color'),
        variable: 'input-focus-border-color',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.tooltip-bg'),
        variable: 'tooltip-bg',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.tooltip-arrow-color'),
        variable: 'tooltip-arrow-color',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.instruction-action-color'),
        variable: 'instruction-action-color',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.instruction-action-hover-bg'),
        variable: 'instruction-action-hover-bg',
        value: '#f8f9fd',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.instruction-action-hover-color'),
        variable: 'instruction-action-hover-color',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.instruction-action-active-bg'),
        variable: 'instruction-action-active-bg',
        value: '#f0f2fa',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.quill-selection-bg'),
        variable: 'quill-selection-bg',
        value: '#3388ff',
        type: 'color',
        suffix: '4d',
    },
    {
        label: t('themeBuilder.variables.quill-comment-highlight-bg'),
        variable: 'quill-comment-highlight-bg',
        value: '#ffeece',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.quill-comment-highlight-active-bg'),
        variable: 'quill-comment-highlight-active-bg',
        value: '#ffc85a',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.quill-comment-highlight-new-bg'),
        variable: 'quill-comment-highlight-new-bg',
        value: '#c2dcff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.table-hover-bg'),
        variable: 'table-hover-bg',
        value: '#f8f9fd',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.plyr-range-fill-bg'),
        variable: 'plyr-range-fill-bg',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.favicon-bg'),
        variable: 'favicon-bg',
        value: '#2155ff',
        type: 'color',
    },
    {
        label: t('themeBuilder.variables.border-radius'),
        variable: 'border-radius',
        value: 6,
        type: 'number',
        suffix: 'px',
    },
];

export {
    ThemeVariable,
    VariableType,
    themeVariables,
};
