
import {defineComponent} from 'vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    setup() {
        const {
            themeEgg,
        } = themeBuilderStore;

        return {
            themeEgg,
        };
    },
});
