import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "btn-group mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_4 = {
  class: "text-decoration-none",
  href: "https://fonts.google.com/",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_InputFont = _resolveComponent("InputFont")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormGroup, {
      modelValue: _ctx.branding.fontName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.branding.fontName) = $event)),
      class: "mb-6",
      "data-test-id": "input-font-name",
      label: _ctx.$t('themeBuilder.fonts.name.label'),
      placeholder: _ctx.$t('themeBuilder.fonts.name.placeholder')
    }, null, 8, ["modelValue", "label", "placeholder"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass(["btn", [!_ctx.branding.fonts.isExternal ? 'btn-primary' : 'btn-outline-primary']]),
        "data-test-id": "btn-files-font",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleExternalFont(false)))
      }, _toDisplayString(_ctx.$t('themeBuilder.fonts.actions.uploadFont')), 3),
      _createElementVNode("button", {
        class: _normalizeClass(["btn", [_ctx.branding.fonts.isExternal ? 'btn-primary' : 'btn-outline-primary']]),
        "data-test-id": "btn-external-font",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleExternalFont(true)))
      }, _toDisplayString(_ctx.$t('themeBuilder.fonts.actions.externalFont')), 3)
    ]),
    (!_ctx.branding.fonts.isExternal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "text-primary text-decoration-underline cursor-pointer mb-4",
            "data-test-id": "link-toggle-variable-font",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleVariableFont && _ctx.toggleVariableFont(...args)))
          }, _toDisplayString(_ctx.$t(`themeBuilder.fonts.upload.${_ctx.branding.fonts.isVariable ? 'fontWeights' : 'variable'}`)), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadFontWeights, (fontWeight, index) => {
            return (_openBlock(), _createBlock(_component_InputFont, {
              key: `font-weight-${fontWeight}`,
              class: _normalizeClass({'mt-4': index}),
              "data-test-id": `input-font-weight-${fontWeight}`,
              "font-weight": fontWeight,
              "theme-font-weight": _ctx.findFontWeight(fontWeight),
              onFontRemoved: ($event: any) => (_ctx.removeFontWeight(fontWeight)),
              onFontUploaded: ($event: any) => (_ctx.uploadFontWeight(fontWeight, $event))
            }, null, 8, ["class", "data-test-id", "font-weight", "theme-font-weight", "onFontRemoved", "onFontUploaded"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_FormGroup, {
            modelValue: _ctx.branding.fonts.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.branding.fonts.value) = $event)),
            "data-test-id": "input-font-external",
            "help-text": _ctx.$t('themeBuilder.fonts.external.helpText'),
            label: _ctx.$t('themeBuilder.fonts.external.label'),
            placeholder: _ctx.$t('themeBuilder.fonts.external.placeholder')
          }, {
            "help-text": _withCtx(({helpText}) => [
              _createTextVNode(_toDisplayString(helpText) + " ", 1),
              _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('themeBuilder.fonts.external.googleFonts')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "help-text", "label", "placeholder"])
        ]))
  ]))
}