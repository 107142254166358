
import {defineComponent} from 'vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    setup() {
        const {branding} = themeBuilderStore;

        return {
            branding,
        };
    },
    computed: {
        bannerImage(): string {
            return this.branding.onboardingImage?.original
                // eslint-disable-next-line global-require
                || require('@/assets/images/auth/default-login.png');
        },
    },
});
