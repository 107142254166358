
import {defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import ComponentMimics from '@/components/themeBuilder/ComponentMimics.vue';
import EditVariables from '@/components/themeBuilder/EditVariables.vue';
import EditVisuals from '@/components/themeBuilder/EditVisuals.vue';
import Error from '@/components/common/Error.vue';
import themeBuilderStore from '@/store/themeBuilder';

export default defineComponent({
    name: 'ThemeBuilder',
    components: {
        BtnResource,
        ComponentMimics,
        EditVariables,
        EditVisuals,
        Error,
    },
    setup() {
        const {
            branding,
            cssVariables,
            themeIsMagic,
        } = themeBuilderStore;

        return {
            branding,
            cssVariables,
            themeIsMagic,
            hideError: ref<boolean>(false),
            inputStep: ref<number>(0),
            inputSteps: [
                EditVariables,
                EditVisuals,
            ],
        };
    },
    computed: {
        lastInputStepReached(): boolean {
            return this.inputStep === this.inputSteps.length - 1;
        },
        mimicComponentsStyle(): Record<string, any> {
            return this.branding.fontName
                ? {fontFamily: `${this.branding.fontName}, inter, sans-serif`}
                : {};
        },
    },
    async created() {
        await themeBuilderStore.fetch();

        // After successfully fetching the branding, don't show future errors.
        this.hideError = true;
    },
    methods: {
        saveBranding() {
            themeBuilderStore.save();
        },
    },
});
